import React from "react";
import { Box, Container, Typography } from "@mui/material";
import Layout from "../components/base-structure/Layout";
import "../styles/Policy.scss";
import Seo from "../components/common/SEO";

const content = [
  {
    id: 1,
    metaTitle: "Privacy Policy - Invocom",
    metaDescription:
      "Invocom's main priority is the privacy of its visitors, we've everything documented here; type of information, collection of data, & how we intend to use it.",
  },
];

export default function policy() {
  return (
    <Layout>
      {content.map((contents) => (
        <div key={contents.id}>
          <Seo
            title={contents.metaTitle}
            description={contents.metaDescription}
            tag="index"
            pageUrl="privacy-policy/"
          />
        </div>
      ))}
      <Container>
        <Box className="policy-wrapper">
          <Box className="policy-head">
            <Typography variant="h1">Privacy Policy</Typography>
            <Typography variantMapping="p">
              At InvoCom, accessible from invocom.io, one of our main priorities
              is the privacy of our visitors. This Privacy Policy document
              contains types of information that is collected and recorded by
              InvoCom and how we use it.
              <br />
              <br />
              If you have additional questions or require more information about
              our Privacy Policy, do not hesitate to contact us.
              <br />
              <br />
              This Privacy Policy applies only to our online activities and is
              valid for visitors to our website with regards to the information
              that they shared and/or collected in InvoCom. This policy is not
              applicable to any information collected offline or via channels
              other than this website.
            </Typography>
          </Box>
          <Box className="policy-content">
            <Typography variant="h2">Consent</Typography>
            <Typography variantMapping="p">
              By using our website, you hereby consent to our Privacy Policy and
              agree to its terms.
            </Typography>
          </Box>
          <Box className="policy-content">
            <Typography variant="h2">Information we collect</Typography>
            <Typography variantMapping="p">
              The personal information that you are asked to provide, and the
              reasons why you are asked to provide it, will be made clear to you
              at the point we ask you to provide your personal information.
              <br />
              <br />
              If you contact us directly, we may receive additional information
              about you such as your name, email address, phone number, the
              contents of the message and/or attachments you may send us, and
              any other information you may choose to provide.
              <br />
              <br />
              When you register for an Account, we may ask for your contact
              information, including items such as name, company name, address,
              email address, and telephone number.
            </Typography>
          </Box>
          <Box className="policy-content">
            <Typography variant="h2">How we use your information</Typography>
            <Typography variantMapping="p">
              <Typography className="policylist-Line">
                We use the information we collect in various ways, including to:
              </Typography>
              <br />
              <Box className="policy-listBox">
                <ul>
                  <li>Provide, operate, and maintain our website</li>
                  <li>Improve, personalize, and expand our website</li>
                  <li>Understand and analyze how you use our website</li>
                  <li>
                    Develop new products, services, features, and functionality
                  </li>
                  <li>
                    Communicate with you, either directly or through one of our
                    partners, including for customer service, to provide you
                    with updates and other information relating to the website,
                    and for marketing and promotional purposes
                  </li>
                  <li>Send you emails</li>
                  <li>Find and prevent fraud</li>
                </ul>
              </Box>
            </Typography>
          </Box>
          <Box className="policy-content">
            <Typography variant="h2">Log Files</Typography>
            <Typography variantMapping="p">
              InvoCom follows a standard procedure of using log files. These
              files log visitors when they visit websites. All hosting companies
              do this as part of hosting services' analytics. The information
              collected by log files includes internet protocol (IP) addresses,
              browser type, Internet Service Provider (ISP), date and time
              stamp, referring/exit pages, and possibly the number of clicks.
              These are not linked to any information that is personally
              identifiable. The purpose of the information is for analyzing
              trends, administering the site, tracking users' movement on the
              website, and gathering demographic information.
            </Typography>
          </Box>
          <Box className="policy-content">
            <Typography variant="h2">Cookies and Web Beacons</Typography>
            <Typography variantMapping="p">
              Like any other website, InvoCom uses 'cookies'. These cookies are
              used to store information including visitors' preferences, and the
              pages on the website that the visitor accessed or visited. The
              information is used to optimize the users' experience by
              customizing our web page content based on visitors browser type
              and/or other information.
            </Typography>
          </Box>
          <Box className="policy-content">
            <Typography variant="h2">
              Advertising Partner's Privacy Policies
            </Typography>
            <Typography variantMapping="p">
              You may consult this list to find the Privacy Policy for each of
              the advertising partners of InvoCom.
              <br />
              <br />
              Third-party ad servers or ad networks use technologies like
              cookies, JavaScript, or Web Beacons that are used in their
              respective advertisements and links that appear on InvoCom, which
              are sent directly to users' browsers. They automatically receive
              your IP address when this occurs. These technologies are used to
              measure the effectiveness of their advertising campaigns and/or to
              personalize the advertising content that you see on websites that
              you visit.
              <br />
              <br />
              Note that InvoCom has no access to or control over these cookies
              that are used by third-party advertisers.
            </Typography>
          </Box>
          <Box className="policy-content">
            <Typography variant="h2">Third-Party Privacy Policies</Typography>
            <Typography variantMapping="p">
              InvoCom's Privacy Policy does not apply to other advertisers or
              websites. Thus, we are advising you to consult the respective
              Privacy Policies of these third-party ad servers for more detailed
              information. It may include their practices and instructions about
              how to opt out of certain options.
              <br />
              <br />
              You can choose to disable cookies through your individual browser
              options. To know more detailed information about cookie management
              with specific web browsers, it can be found on the browsers'
              respective websites.
            </Typography>
          </Box>
          <Box className="policy-content">
            <Typography variant="h2">
              CCPA Privacy Rights (Do Not Sell My Personal Information)
            </Typography>
            <Typography variantMapping="p">
              Under the CCPA, among other rights, California consumers have the
              right to:
              <br />
              <br />
              Request that a business that collects a consumer's personal data
              disclose the categories and specific pieces of personal data that
              a business has collected about consumers.
              <br />
              <br />
              Request that a business deletes any personal data about the
              consumer that a business has collected.
              <br />
              <br />
              Request that a business that sells a consumer's personal data, not
              sell the consumer's personal data.
              <br />
              <br />
              If you make a request, we have one month to respond to you. If you
              would like to exercise any of these rights, please contact us.
            </Typography>
          </Box>
          <Box className="policy-content">
            <Typography variant="h2">GDPR Data Protection Rights</Typography>
            <Typography variantMapping="p">
              We would like to make sure you are fully aware of all of your data
              protection rights. Every user is entitled to the following:
              <br />
              <br />
              The right to rectification – You have the right to request that we
              correct any information you believe is inaccurate. You also have
              the right to request that we complete the information you believe
              is incomplete.
              <br />
              <br />
              The right to erasure – You have the right to request that we erase
              your personal data, under certain conditions.
              <br />
              <br />
              The right to restrict processing – You have the right to request
              that we restrict the processing of your personal data, under
              certain conditions.
              <br />
              <br />
              The right to object to processing – You have the right to object
              to our processing of your personal data, under certain conditions.
              <br />
              <br />
              The right to data portability – You have the right to request that
              we transfer the data that we have collected to another
              organization, or directly to you, under certain conditions.
              <br />
              <br />
              If you make a request, we have one month to respond to you. If you
              would like to exercise any of these rights, please contact us.
            </Typography>
          </Box>
          <Box className="policy-content">
            <Typography variant="h2">Refund policy </Typography>
            <Typography variantMapping="p">
              <Box className="policy-listBox">
                <ul>
                  <li>
                    Customers can claim a refund within 30 days of purchase
                  </li>
                  <li>
                    The claim can only be processed after an email requesting a
                    refund has been initiated by the client and received on
                    InvoCom’s official email address
                  </li>
                  <li>The refund request will be processed within 7 days</li>
                </ul>
              </Box>
            </Typography>
          </Box>
          <Box className="policy-content">
            <Typography variant="h2">Cancellation policy</Typography>
            <Typography variantMapping="p">
              <Box className="policy-listBox">
                <ul>
                  <li>We have a ‘no questions asked’ cancellation policy</li>
                  <li>
                    Request can only be processed after an email requesting a
                    cancellation has been initiated by the client and received
                    on InvoCom’s official email address
                  </li>
                  <li>
                    The cancellation request will be processed within 7 days
                  </li>
                </ul>
              </Box>
            </Typography>
          </Box>
        </Box>
      </Container>
    </Layout>
  );
}
